export const translate = {
  en: {
    translation: {
      //Account
      Logout: "Logout",
      createaccount: "Log in / Register",
      login: "Log in",
      register: "Register",

      //Navbar
      Home: "Home",
      My_Profile: "My Profile",


      Assistance: "Support",
      Extensions: "Extensions",
      Organization: "Organization",
      Personalinfos: "Personal Informations",
    },
  },

  fr: {
    translation: {
      //Account
      Logout: "Se Déconnecter",
      createaccount: "Connexion / Créer un compte",
      login: "Connexion",
      register: "Créer un compte",

      //Navbar
      Home: "Accueil",
      My_Profile: "Mon Profil",
      

      Assistance: "Assistance",
      Extensions: "Extensions",
      Organization: "Organisation",
      Personalinfos: "Informations Personnelles",
    },
  },

  ja: {
    translation: {},
  },
};
