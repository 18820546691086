import { KeycloakInstance, KeycloakLoginOptions } from "keycloak-js";
import { Auth, UserProfile } from ".";
import { team } from "./AuthContext";
import { ThemeType } from "./Components/TopBar";

export const AVAILABLE_APPS = [
  "roi",
  "dashboard",
  "approve",
  "jetforce",
  "tag-generator",
  "register",
  "predict",
  "estimate",
];

export type AppAuthorizationsType = {
  apps: string[];
  id: string;
};
export class KeycloakAdapter implements Auth {
  constructor(private readonly keycloak: KeycloakInstance) {}

  get authenticated(): boolean | undefined {
    return this.keycloak.authenticated;
  }

  get token(): string | undefined {
    return this.keycloak.token;
  }

  login(options?: KeycloakLoginOptions) {
    const urlParams = new URLSearchParams(window.location.search);
    const idpHint = urlParams.get("idpHint");
    window.history.pushState(
      window.history.state,
      document.title,
      document.location.href
    );
    return this.keycloak.login({ ...(idpHint ? { idpHint } : {}), ...options });
  }

  logout() {
    return this.keycloak.logout();
  }

  // TODO: add test for missing team situation
  async loadUserProfile(): Promise<UserProfile | undefined> {
    const profile = await this.keycloak.loadUserProfile();
    const tokenData = this.keycloak.tokenParsed as any;

    const teamName = tokenData["producer_team_name"] as string[] | undefined;
    let teamId = tokenData["producer_team_uuid"] as string | undefined;

    const appTheme = (tokenData["app_theme"] ?? "dark") as ThemeType;
    const approveModal = (tokenData["approve_modal"] ?? false) as boolean;
    const teams = tokenData["all_groups"];
    const realTeams: team[] = Object.values(teams);
    if (!teamId && teamName && teamName[0]) {
      teamId = realTeams.find((f) => f.realName === teamName[0])?.id;
    }

    const defaultRoles = Object.values(
      realTeams.find((t) => t.id === teamId)?.roles ?? []
    );

    const availableApps = defaultRoles.filter((roles: string) =>
      AVAILABLE_APPS.includes(roles)
    );
    const userAvailableApps = tokenData[
      "user_app_authorizations"
    ] as AppAuthorizationsType[];
    const uniqAvailableApps = availableApps.filter(
      (item: string, pos: number) => {
        return availableApps.indexOf(item) == pos;
      }
    );

    return {
      id: this.keycloak.tokenParsed?.sub,
      ...profile,
      isAdmin: tokenData["adminInGroups"]
        ? tokenData["adminInGroups"].includes(tokenData["producer_team_uuid"])
        : false,
      availableApps: uniqAvailableApps,
      userAvailableApps:
        userAvailableApps?.find((u) => u.id === teamId)?.apps ?? [],
      teams: realTeams,
      appTheme: appTheme,
      company: tokenData["company"],
      producer_team_uuid: teamId,
      defaultTeam:
        teamId && teamName
          ? {
              id: teamId,
              name: teamName.join(),
              realName: teamName.join(),
              roles: defaultRoles,
            }
          : undefined,
      approveModal,
      lang: tokenData["locale"] ?? "en",
    };
  }

  refreshSession() {
    return (
      this.keycloak
        // Refresh access when within 30 seconds of expiring
        //
        // If you want to force refresh the token in development mode,
        // you can use 295 seconds as a value (which will refresh the token
        // every five seconds, since tokens expire every 5 minutes, that is
        // every 300 seconds).
        .updateToken(30)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Refreshed access token");
          }
        })
        .catch(() => {
          // Keycloak failed to update the access token
          // We have to re-authenticate
          this.keycloak.clearToken();
          return this.keycloak.login();
        })
    );
  }
}
