import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./styles/navbar.module.scss";
import { useTranslation } from "react-i18next";
import { useAuth, useUser } from "../AuthContext";
import ListGroups from "./ListGroups";
import { ThemeType } from "./TopBar";
import useOutsideClick from "../UseOutsideClick";
import { UserProfile } from "..";

export default function AccountButton({
  backendUrl,
  //handleUpdateUserTheme,
  theme,
  connectUrl,
}: {
  backendUrl?: string;
  theme?: ThemeType;
  handleUpdateUserTheme?: (theme: ThemeType) => Promise<void>;
  pageName?: string;
  connectUrl?: string;
}) {
  const [showLogout, setLogoutVisible] = useState(false);
  const toggleLogoutVisibility = useCallback(
    () => setLogoutVisible((visible) => !visible),
    []
  );

  const { auth } = useAuth();
  const handleLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const { t } = useTranslation();

  const refDivInside = useRef<HTMLDivElement>(null);

  useOutsideClick(refDivInside, () => {
    showLogout && toggleLogoutVisibility();
  });
  const user = useUser();

  useEffect(() => {
    async function setProfile(user: UserProfile) {
      const backendUrl = process.env["REACT_APP_BACKEND_URL"];
      const url = new URL(`${backendUrl}/user/configure-user`).toString();

      const init: RequestInit = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({
          userCompany: user.company ?? "My Company",
          userId: user.id,
        }),
      };
      await fetch(url, init);
      window.location.reload();
    }
    if (user && !user.defaultTeam) {
      setProfile(user);
    }
  }, [user]);

  const username =
    typeof user === "object"
      ? `${user?.firstName} ${user?.lastName}`
      : undefined;

  const initials =
    typeof user === "object"
      ? `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
      : undefined;

  const teams = typeof user === "object" ? user.teams : undefined;

  const defaultTeam = typeof user === "object" ? user.defaultTeam : undefined;

  const userApps = getUserApps(user?.availableApps ?? []);

  return (
    <div ref={refDivInside}>
      <button
        className={classNames(styles.navbar_btnlogin, styles.btn)}
        onClick={toggleLogoutVisibility}
      >
        <div
          className={classNames(
            process.env.REACT_APP_IS_STAGING
              ? styles.compteStaging
              : styles.compte
          )}
        >
          <p className={classNames(styles.letter)}>{initials}</p>
        </div>
      </button>
      {showLogout && (
        <div className={classNames(styles.option_log, "row")}>
          <div className={classNames(styles.navbar_divlogin)}>
            <div
              className={classNames(
                process.env.REACT_APP_IS_STAGING
                  ? styles.compteStaging
                  : styles.compte
              )}
            >
              <p className={classNames(styles.letter)}>{initials}</p>
            </div>
          </div>
          <div className={classNames(styles.userinfo)}>
            <>
              <p className={classNames(styles.nameuser, styles.name)}>
                {username}
              </p>

              {teams && defaultTeam ? (
                <ListGroups
                  backendUrl={backendUrl}
                  defaultValue={defaultTeam}
                  teams={teams}
                />
              ) : (
                ""
              )}
            </>
          </div>

          {connectUrl && (
            <div
              className={classNames(styles.themecolor, styles.navbar_divlogin)}
            >
              <label htmlFor="darkTheme">
                <a href={`${connectUrl}profil`} target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    fill={theme === "dark" ? "white" : "black"}
                  >
                    <g>
                      <path d="M0,0h24v24H0V0z" fill="none" />
                      <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
                    </g>
                  </svg>
                </a>
              </label>
            </div>
          )}

          <p className={classNames(styles.logout)} onClick={handleLogout}>
            {t("Logout")}
          </p>

          <div className={classNames(styles.appzone)}>
            {userApps.map((app) => (
              <div className={classNames(styles.selectapp)}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={process.env[`REACT_APP_${app.toUpperCase()}_URL`]}
                >
                  {mapApptoSvg(app)}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function mapApptoSvg(app: string) {
  switch (app) {
    case "approve":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52.6"
          viewBox="0 0 52 52.6"
        >
          <g
            id="Logo-Approve"
            transform="translate(3862 -6492.257)"
            // style="isolation: isolate"
          >
            <path
              id="Rectangle_1425"
              data-name="Rectangle 1425"
              d="M0,0H52a0,0,0,0,1,0,0V12A10,10,0,0,1,42,22H10A10,10,0,0,1,0,12V0A0,0,0,0,1,0,0Z"
              transform="translate(-3862 6522.257)"
              fill="#064b93"
            />
            <path
              id="Rectangle_1426"
              data-name="Rectangle 1426"
              d="M0,0H52a0,0,0,0,1,0,0V20A10,10,0,0,1,42,30H10A10,10,0,0,1,0,20V0A0,0,0,0,1,0,0Z"
              transform="translate(-3810 6522.257) rotate(180)"
              fill="#1794cd"
            />
            <path
              id="Tracé_1452"
              data-name="Tracé 1452"
              d="M30.771,12.129a17.25,17.25,0,0,1,8.591,3.99c-.733.669-1.445,1.364-2.133,2.067a14.353,14.353,0,1,0,4.912,8.238,25.172,25.172,0,0,1,2.662-1.6,17.332,17.332,0,1,1-14.034-12.7"
              transform="translate(-3863.865 6487.129)"
              fill="#004b93"
              fill-rule="evenodd"
            />
            <path
              id="Tracé_1453"
              data-name="Tracé 1453"
              d="M16.057,25.052a17.274,17.274,0,0,1,6.9-2.958,22.4,22.4,0,0,1,3.205,8.34c5.543-7.163,12.463-16.041,18.99-17.352a29.179,29.179,0,0,0-1.9,9.417c-7.008,2.056-13.689,10.048-18.3,15.381-1.628-4.321-4.6-10.709-8.9-12.829"
              transform="translate(-3861.745 6487.592)"
              fill="#fff"
              fill-rule="evenodd"
            />
            <text
              id="AP"
              transform="translate(-3847 6539.257)"
              fill="#fff"
              stroke="#feffff"
              stroke-width="0.6"
              font-size="17"
              font-family="Young"
              letter-spacing="0.03em"
            >
              <tspan x="0" y="0">
                AP
              </tspan>
            </text>
          </g>
        </svg>
      );
    case "dashboard":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="104"
          height="104.6"
          viewBox="0 0 104 104.6"
        >
          <g id="Logo-Dashboard" transform="translate(0.13 -0.276)">
            <g id="Logo-Approve" transform="translate(-0.13 0.276)">
              <path
                id="Rectangle_1425"
                data-name="Rectangle 1425"
                d="M0,0H104a0,0,0,0,1,0,0V32A10,10,0,0,1,94,42H10A10,10,0,0,1,0,32V0A0,0,0,0,1,0,0Z"
                transform="translate(0 62)"
                fill="#064b93"
              />
              <path
                id="Rectangle_1426"
                data-name="Rectangle 1426"
                d="M0,0H104a0,0,0,0,1,0,0V52A10,10,0,0,1,94,62H10A10,10,0,0,1,0,52V0A0,0,0,0,1,0,0Z"
                transform="translate(104 62) rotate(180)"
                fill="#fbba00"
              />
              <text
                id="DB"
                transform="translate(29 94)"
                fill="#fff"
                stroke="#feffff"
                stroke-width="0.6"
                font-size="33"
                font-family="Young"
                letter-spacing="0.03em"
              >
                <tspan x="0" y="0">
                  DB
                </tspan>
              </text>
              <g
                id="Groupe_775"
                data-name="Groupe 775"
                transform="translate(5.887 0.931)"
              >
                <path
                  id="Tracé_1416"
                  data-name="Tracé 1416"
                  d="M20.53,12.814H13.6a.858.858,0,0,0-.788.913V57.66a.858.858,0,0,0,.788.913H20.53a.858.858,0,0,0,.788-.913V13.727A.858.858,0,0,0,20.53,12.814Z"
                  transform="translate(3.013 3.353)"
                  fill="#004b93"
                />
                <path
                  id="Tracé_1417"
                  data-name="Tracé 1417"
                  d="M14.929,12.581H8a.858.858,0,0,0-.788.913V57.427A.858.858,0,0,0,8,58.34h6.926a.858.858,0,0,0,.788-.913V13.494A.858.858,0,0,0,14.929,12.581Z"
                  transform="translate(-7.214 2.927)"
                  fill="#fff"
                />
                <path
                  id="Tracé_1418"
                  data-name="Tracé 1418"
                  d="M26.361,10.9H19.434a.858.858,0,0,0-.788.913V60.493a.858.858,0,0,0,.788.913h6.926a.858.858,0,0,0,.788-.913V11.815A.858.858,0,0,0,26.361,10.9Z"
                  transform="translate(13.659 -0.138)"
                  fill="#fff"
                />
                <path
                  id="Tracé_1419"
                  data-name="Tracé 1419"
                  d="M32.192,9.263H25.263a.858.858,0,0,0-.788.913V64.144a.858.858,0,0,0,.788.913h6.929a.858.858,0,0,0,.788-.913V10.176a.858.858,0,0,0-.788-.913Z"
                  transform="translate(24.302 -3.131)"
                  fill="#004b93"
                />
                <path
                  id="Tracé_1420"
                  data-name="Tracé 1420"
                  d="M43.987,9.263H37.058a.858.858,0,0,0-.788.913V64.144a.858.858,0,0,0,.788.913h6.929a.858.858,0,0,0,.788-.913V10.176a.858.858,0,0,0-.788-.913Z"
                  transform="translate(45.838 -3.131)"
                  fill="#004b93"
                />
                <path
                  id="Tracé_1421"
                  data-name="Tracé 1421"
                  d="M38.021,7.1H31.094a.858.858,0,0,0-.788.913V67.448a.858.858,0,0,0,.788.913h6.926a.858.858,0,0,0,.788-.913V8.006a.858.858,0,0,0-.788-.913Z"
                  transform="translate(34.949 -7.093)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    case "jetforce":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="104"
          height="104.7"
          viewBox="0 0 104 104.7"
        >
          <g id="Logo-Approve" transform="translate(0.2 0.3)">
            <path
              id="Rectangle_1425"
              d="M-.2,61.1h104V94.3a10.116,10.116,0,0,1-10,10.1H9.8A10.051,10.051,0,0,1-.2,94.3V61.1Z"
              fill="#064b93"
            />
            <path
              id="Rectangle_1426"
              d="M103.8,61.1H-.2V9.8A10.116,10.116,0,0,1,9.8-.3h84a10.051,10.051,0,0,1,10,10.1V61.1Z"
              fill="#98c226"
            />
            <text
              id="JF"
              transform="translate(32.806 93.725)"
              fill="#fff"
              font-size="34"
              font-family="Young"
            >
              <tspan x="0" y="0">
                JF
              </tspan>
            </text>
            <text
              id="JF-2"
              data-name="JF"
              transform="translate(32.806 93.725)"
              fill="none"
              stroke="#feffff"
              stroke-width="0.6"
              font-size="34"
              font-family="Young"
            >
              <tspan x="0" y="0">
                JF
              </tspan>
            </text>
            <path
              id="Tracé_1"
              data-name="Tracé 1"
              d="M72.5,12.9a9.337,9.337,0,0,1-1.3,1.5c-4.5,4.6-9.9,8-15.5,11.1-.8.4-1.7.4-2,1.5-2.2,6.6-4.7,13-7.5,19.3-1.6,3.6-3.3,7.1-6.3,9.8-1.3,1.2-1.6,1.2-2-.8a34.229,34.229,0,0,1-.1-10.6c.2-3.1.2-6.4.2-9.7,0-1.1-.3-1.3-1.4-.9a45.309,45.309,0,0,0-11.4,6.8c-4,3-7.9,6.3-11.8,9.5C9.2,53.8,5.1,57.4,1.2,61a5.716,5.716,0,0,1-.8.7,2.651,2.651,0,0,1-.6.5V32.4a7.719,7.719,0,0,1,1.5-.2,163.842,163.842,0,0,0,18.2-3.9,48.7,48.7,0,0,0,11.2-4.2c1.4-.8,1.4-1.2,0-2.2-4.4-3.4-9-6.7-13.4-10.1a21.02,21.02,0,0,1-3.7-3.6,7.1,7.1,0,0,1,3.1-1.3,18.809,18.809,0,0,1,6.7.2,58.29,58.29,0,0,1,7.2,1.8c4.7,1.7,9.4,3.3,14.2,4.7,2.1.6,4.1,1.4,6.5.5a89.155,89.155,0,0,1,11.5-3.3c2.6-.6,5.2-1.3,8-.9C72.6,10,73.4,11.3,72.5,12.9Z"
              fill="#fff"
            />
            <path
              id="Tracé_2"
              data-name="Tracé 2"
              d="M75.9,33.2S58,41.8,55.8,56.9H69.4C69.4,57,64.7,47.3,75.9,33.2Z"
              fill="#064b93"
            />
            <path
              id="Tracé_3"
              data-name="Tracé 3"
              d="M86.7,26.4S70.4,43.4,74.9,57H99.3S88,57,86.7,26.4Z"
              fill="#064b93"
            />
          </g>
        </svg>
      );
    default:
      return "";
  }
}

export function getUserApps(userApps: string[]): APPS_TYPE[] {
  return userApps.filter((app) => USER_APPS_EXTERNAL.includes(app)).sort();
}

export const ALL_APPS = [
  "approve",
  "roi",
  "dashboard",
  "jetforce",
  "tag-generator",
  "register",
  "predict",
  "estimate",
  "appshop",
  "foilshop",
  "alphajet-viewer",
  "guidelines",
];

export type APPS_TYPE = (typeof ALL_APPS)[number];

export const USER_APPS_EXTERNAL: APPS_TYPE[] = [
  "approve",
  "dashboard",
  "jetforce",
];
