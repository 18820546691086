export interface MachineModel {
  displayName: string;
  type: MachineModelType;
  canPrintInk: boolean;
  isSheet: boolean;
  isPrint: boolean;
  kernelVersion: number;
}

export type MachineModelType = (typeof MACHINE_MODEL_TYPES)[number];

export function MachineModelParser(type: MachineModelType): MachineModel {
  return {
    type,
    ...MACHINE_MODEL_INFO[type as MachineModelType],
  };
}

export const MACHINE_MODEL_TYPES = [
  "JETvarnish",
  "JETvarnish 3DS",
  "JETvarnish 3DL",
  "JETvarnish 3D",
  "JETvarnish 3D Web",
  "JETvarnish 3D EVO",
  "JETvarnish 3D One",
  "JETvarnish 3D 52L",
  "ALPHAJET",
  "AccurioShine 3600",
  "OCTOPUS",
  "COMPACT",
  "ALTIJET",
  "ZRX",
] as const;

export const MACHINE_MODEL_INFO: {
  [type in MachineModelType]: Omit<MachineModel, "type">;
} = {
  ALPHAJET: {
    displayName: "ALPHAJET",
    canPrintInk: true,
    isSheet: true,
    isPrint: true,
    kernelVersion: 2,
  },
  JETvarnish: {
    displayName: "JETvarnish",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3DL": {
    displayName: "JETvarnish 3DL",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3DS": {
    displayName: "JETvarnish 3DS",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3D": {
    displayName: "JETvarnish 3D",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3D Web": {
    displayName: "JETvarnish 3D Web",
    canPrintInk: false,
    isSheet: false,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3D EVO": {
    displayName: "JETvarnish 3D EVO",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  ZRX: {
    displayName: "ZRX",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3D One": {
    displayName: "JETvarnish 3D One",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "JETvarnish 3D 52L": {
    displayName: "JETvarnish 3D 52L",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  "AccurioShine 3600": {
    displayName: "AccurioShine 3600",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 1,
  },
  COMPACT: {
    displayName: "COMPACT",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 2,
  },
  OCTOPUS: {
    displayName: "OCTOPUS",
    canPrintInk: false,
    isSheet: false,
    isPrint: false,
    kernelVersion: 2,
  },
  ALTIJET: {
    displayName: "AltiJet",
    canPrintInk: false,
    isSheet: true,
    isPrint: true,
    kernelVersion: 2,
  },
};
