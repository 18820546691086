import React, { ReactElement, ReactNode } from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenuLink.scss";
import { NavLink } from "react-router-dom";

export interface MGILeftMenuLinkProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // text to display
  label: string | ReactElement;
  svg: ReactNode;
  url: string;
  project: string;
  // actions
  onClick?: () => void;
  currentUrl?: string;
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenuLink = ({
  styleClass = "primary",
  theme,
  label,
  svg,
  url,
  project,
  currentUrl,
  ...props
}: MGILeftMenuLinkProps) => {
  const mode = "storybook-leftmenulink--" + styleClass;
  return (
    <NavLink
      to={url}
      data-theme={theme ? theme : "light"}
      className={({ isActive }) => {
        return isActiveSubMenu(project, url, currentUrl) || isActive
          ? "active"
          : undefined;
      }}
    >
      <div
        data-theme={theme ? theme : "light"}
        className={["storybook-leftmenulink", mode].join(" ")}
        {...props}
      >
        <div className="svgzone">{svg}</div>
        <div className="linkZone">{label}</div>
      </div>
    </NavLink>
  );
};

function isActiveSubMenu(
  project: string,
  url: string,
  currentUrl: string | undefined
) {
  const topEventIf =
    project === "predict" &&
    currentUrl?.includes("dashboard") &&
    url.includes("dashboard");

  const sparekitIf =
    project === "predict" &&
    url.includes("sparekits") &&
    (currentUrl?.includes("sparekits") ||
      currentUrl?.includes("spareparts") ||
      currentUrl?.includes("consumables"));
  return topEventIf || sparekitIf;
}
