import { useState } from "react";
import classNames from "classnames";
import styles from "./styles/navbar.module.scss";
import AccountButton from "./AccountButton";
import "./Internationalization/i18n";
import i18n, { AppLanguage } from "./Internationalization/i18n";
import { ThemeType } from "./TopBar";
import { useAuth } from "..";
import { useTranslation } from "react-i18next";
export const Bar = ({
  logo,
  theme,
  handleUpdateUserTheme,
  changeLang,
  pageName,
  currentLang,
  backendUrl,
  currentConnectPage,
  connectUrl,
}: {
  logo: string;
  pageName?: string;
  theme?: ThemeType;
  handleUpdateUserTheme?: (theme: ThemeType) => Promise<void>;
  changeLang: (lang: AppLanguage) => void;
  currentLang: string;
  backendUrl?: string;
  currentConnectPage?: string;
  connectUrl?: string;
}) => {
  const [usedLang, setUsedLang] = useState(currentLang.split("-")[0]);

  const [showSelectedLang, setShowSelectLang] = useState(false);
  const showLanguage = () => {
    setShowSelectLang((showSelectedLang) => !showSelectedLang);
  };

  const { t } = useTranslation();

  const changeLangInternal = (lang: AppLanguage) => {
    setUsedLang(lang);
    i18n.changeLanguage(lang);
    changeLang(lang);
  };

  const { auth } = useAuth();

  return (
    <div
      data-theme={theme ?? "dark"}
      className={classNames(styles.navbar, styles.whitemode)}
    >
      <div className={classNames(styles.navbartitle)}>
        {pageName === "Dashboard" ? (
          <div className={classNames(styles.place_logo)}>
            <img src={logo} alt="MGI connect" />
          </div>
        ) : pageName === "Connect" ? (
          <div className={classNames(styles.place_text)}>
            <img
              className={classNames(styles.displaynone)}
              src={logo}
              alt="MGI connect"
            />
            <p>{t(mapCurrentPageToTitle(currentConnectPage))}</p>
          </div>
        ) : null}
      </div>
      <div className={classNames(styles.searchbarzone)}>
        <div className={classNames(styles.searchbar)}>
          <div className={classNames(styles.searchbarimg)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.586"
              height="15.586"
              viewBox="0 0 15.586 15.586"
            >
              <path
                id="Tracé_56"
                data-name="Tracé 56"
                d="M13.88,12.575h-.688l-.244-.235a5.666,5.666,0,1,0-.609.609l.235.244v.688l4.352,4.343,1.3-1.3Zm-5.223,0a3.917,3.917,0,1,1,3.917-3.917A3.912,3.912,0,0,1,8.658,12.575Z"
                transform="translate(-2.85 -2.85)"
                fill="#fff"
                stroke="#1e2023"
                strokeWidth="0.3"
              />
            </svg>
          </div>
          <input type="text" name="search" id="" />
        </div>
      </div>
      <div className={classNames(styles.logomgi)}>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="233.2"
          height="122.244"
          viewBox="0 0 233.2 122.244"
        >
          <g id="logomgilittle" transform="translate(45 23.9)">
            <g id="Groupe_478">
              <g id="Groupe_474">
                <path
                  id="Tracé_1192"
                  d="M186-23.9H164.6l-.5,1.4-1.9,9.3-.5,1.4-.9,5.1v1.4l-.9,2.8-.5,1.9h15.3A11.926,11.926,0,0,0,184.9-9h0l1.4-5.6v-.9l1.4-6.5.5-1.9Z"
                  fill="#fff"
                />
                <path
                  id="Tracé_1193"
                  d="M166.4,3.5h-6L140.9,96h6.5a17.368,17.368,0,0,0,16.3-13.5l16.7-79Z"
                  fill="#fff"
                />
                <path
                  id="Tracé_1194"
                  d="M21.9,80.6,45.6,30,31.7,96H50.8L67,19.8A14.842,14.842,0,0,0,65.1,7.2,13.206,13.206,0,0,0,53.5,2.6a21.25,21.25,0,0,0-5.6.5A18.46,18.46,0,0,0,34,14.7L9.8,64.4,9.4,14.7A11.285,11.285,0,0,0,.1,3,21.95,21.95,0,0,0-5,2.6,22.679,22.679,0,0,0-18.5,7.2a22.676,22.676,0,0,0-7.4,12.6L-42.2,96h19.1L-9.2,30l1.9,49.3.5,6.5c0,1.4.5,3.3.5,4.6.9,4.6,5.1,8.4,9.8,7.9a14.05,14.05,0,0,0,12.5-7l.5-.5a44.524,44.524,0,0,0,3.3-5.1Z"
                  fill="#fff"
                />
                <path
                  id="Tracé_1195"
                  d="M66.5,80.6a14.146,14.146,0,0,0,.5,4.6,9.706,9.706,0,0,0,5.1,7.4A25.119,25.119,0,0,0,86,95.9h22.8a23.12,23.12,0,0,0,20.9-11.2,30.905,30.905,0,0,0,4.2-12.5l6-29.3H117.6l-2.3,10.2h2.3l-3.7,18.1v.5c-.9,2.8-3.3,4.2-7.4,4.2H90.7c-1.9-.5-3.3-1.9-3.3-3.3v-.5L97.2,27a5.016,5.016,0,0,1,4.6-3.7h36.7l4.2-20H105.1A29.988,29.988,0,0,0,86.5,8.6a32.1,32.1,0,0,0-8.8,17.7S67.5,75.1,67,77A10.817,10.817,0,0,0,66.5,80.6Z"
                  fill="#fff"
                />
              </g>
              <path id="Tracé_1451" d="M-45,36.5" fill="#fff" />
            </g>
          </g>
        </svg> */}
      </div>
      <div className={classNames(styles.rightmodule)}>
        <div
          className={classNames(
            styles.navbar_btnlanguages2,
            "nav-item nav-link"
          )}
        >
          {!window.location.href.includes("roi-alphajet") && (
            <button
              onClick={showLanguage}
              className={classNames(styles.changelanguage)}
            >
              {usedLang}
              <div
                className={classNames(
                  styles.button,
                  !showSelectedLang ? "" : styles.view
                )}
              >
                {usedLang === "fr" ? (
                  <p onClick={() => changeLangInternal("en")}>En</p>
                ) : (
                  <p onClick={() => changeLangInternal("fr")}>Fr</p>
                )}
              </div>
            </button>
          )}
          {auth.authenticated ? (
            ""
          ) : (
            <div
              className={classNames(
                styles.navbar_btnlogin,
                styles.marginrightneg
              )}
              onClick={() => !auth.authenticated && auth.login()}
            >
              <button
                className={classNames(
                  styles.navbar_btnlogin,
                  styles.btn,
                  styles.btnmarginnone
                )}
              >
                <div className={classNames(styles.createaccount)}>
                  <p>{t("login")}</p>
                </div>
              </button>
            </div>
          )}
          {auth.authenticated ? (
            <AccountButton
              backendUrl={backendUrl}
              theme={theme}
              handleUpdateUserTheme={handleUpdateUserTheme}
              pageName={pageName}
              connectUrl={connectUrl}
            />
          ) : (
            <a
              className={classNames(styles.navbar_btnlogin)}
              href={`${connectUrl}createAccount`}
            >
              <button
                className={classNames(
                  styles.navbar_btnlogin,
                  styles.btn,
                  styles.btnmarginnone
                )}
              >
                <div className={classNames(styles.createaccount)}>
                  <p>{t("register")}</p>
                </div>
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

function mapCurrentPageToTitle(currentPage?: string) {
  switch (currentPage) {
    case "/profile":
      return "Profile";
    case "/applications":
      return "Applications";
    case "/workteam":
      return "Organization";
    case "/extensions":
      return "Extensions";
    case "/profil":
      return "Personalinfos";
    case "/support":
      return "Assistance";

    default:
      return "";
  }
}
