export * from "./machines/machine.interface";
export * from "./machines/mapMachineToImage";
// import path from "path";

// const jv3D = readFileSync(
//   path.join(__dirname, "./img/equipments/JETvarnish3D.png")
// );
// const jv3DOne = readFileSync(
//   path.join(__dirname, "./img/equipments/JETvarnish3DOne.png")
// );
// const accurioShine = readFileSync(
//   path.join(__dirname, "./img/equipments/AccurioShine.png")
// );
// const jv3D52L = readFileSync(
//   path.join(__dirname, "./img/equipments/Jetvarnish3D52L.png")
// );
// const OctopusMGI = readFileSync(
//   path.join(__dirname, "./img/equipments/OctopusLogo.png")
// );
// const jv3dEvo = readFileSync(
//   path.join(__dirname, "./img/equipments/JETvarnish3DEvo.png")
// );
// const jv3DS = readFileSync(
//   path.join(__dirname, "./img/equipments/JETVARNISH3DS.png")
// );
// const jv3DWeb = readFileSync(
//   path.join(__dirname, "./img/equipments/JETvarnish3DWeb.png")
// );
// const alphajet = readFileSync(
//   path.join(__dirname, "./img/equipments/Alphajet.png")
// );
import jv3D from "./img/equipments/JETvarnish3D.png";
import jv3DOne from "./img/equipments/JETvarnish3DOne.png";
import accurioShine from "./img/equipments/AccurioShine.png";
import jv3D52L from "./img/equipments/Jetvarnish3D52L.png";
import OctopusMGI from "./img/equipments/OctopusLogo.png";
import jv3dEvo from "./img/equipments/JETvarnish3DEvo.png";
import jv3DS from "./img/equipments/JETVARNISH3DS.png";
import jv3DWeb from "./img/equipments/JETvarnish3DWeb.png";
import alphajet from "./img/equipments/Alphajet.png";
import altijet from "./img/equipments/altijet.png";
import compact from "./img/equipments/JETvarnish3DWeb400.png";
import zrx from "./img/equipments/ZRX_logo.png";

export {
  jv3D,
  jv3DOne,
  accurioShine,
  jv3D52L,
  OctopusMGI,
  jv3dEvo,
  jv3DS,
  jv3DWeb,
  alphajet,
  altijet,
  compact,
  zrx,
};
