import React from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenu.scss";

export interface MGILeftMenuOpenDocumentionDashboardProps {
  label: string;
  theme?: Theme;
  styleClass?: string;
  onClick?: () => void;
  url: string;
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenuOpenDocumentionDashboard = ({
  label,
  styleClass = "primary",
  theme,
  onClick,
  url,
  ...props
}: MGILeftMenuOpenDocumentionDashboardProps) => {
  const mode = "storybook-leftmenulink--" + styleClass;
  return (
    <div
      data-theme={theme ? theme : "light"}
      className="documentation"
      // className={({ isActive }) => {
      //   return isActive ? "active" : undefined;
      // }}
    >
      <a
        data-theme={theme ? theme : "light"}
        className={["storybook-leftmenulink", mode].join(" ")}
        href={url}
      >
        <div className="svgzone">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="640"
            height="640"
            viewBox="0 0 640 640"
          >
            <path
              id="download_FILL0_wght400_GRAD0_opsz48"
              d="M480-313,287-506l43-43L450-429V-800h60v371L630-549l43,43ZM220-160q-24,0-42-18t-18-42V-363h60v143H740V-363h60v143q0,24-18,42t-42,18Z"
              transform="translate(-160 800)"
            />
          </svg>
        </div>
        <div className="linkZone">{label}</div>
      </a>
    </div>
  );
};
