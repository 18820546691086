import jv3D from "../img/equipments/JETvarnish3D.png";
import jv3DOne from "../img/equipments/JETvarnish3DOne.png";
import accurioShine from "../img/equipments/AccurioShine.png";
import jv3D52L from "../img/equipments/Jetvarnish3D52L.png";
import OctopusMGI from "../img/equipments/OctopusLogo.png";
import jv3dEvo from "../img/equipments/JETvarnish3DEvo.png";
import jv3DS from "../img/equipments/JETVARNISH3DS.png";
import jv3DL from "../img/equipments/3DL.png";
import jv3DWeb from "../img/equipments/JETvarnish3DWeb.png";
import alphajet from "../img/equipments/Alphajet.png";
import compact from "../img/equipments/JETvarnish3DWeb400.png";
import altijet from "../img/equipments/altijet.png";
import zrx from "../img/equipments/ZRX_logo.png";

import { MachineModelType } from "./machine.interface";

export function mapMachineToImage(type: MachineModelType) {
  switch (type) {
    case "JETvarnish":
      return jv3D;
    case "JETvarnish 3DS":
      return jv3DS;
    case "JETvarnish 3DL":
      return jv3DL;
    case "JETvarnish 3D":
      return jv3D;
    case "JETvarnish 3D Web":
      return jv3DWeb;
    case "JETvarnish 3D EVO":
      return jv3dEvo;
    case "ALPHAJET":
      return alphajet;
    case "JETvarnish 3D One":
      return jv3DOne;
    case "AccurioShine 3600":
      return accurioShine;
    case "JETvarnish 3D 52L":
      return jv3D52L;
    case "OCTOPUS":
      return OctopusMGI;
    case "COMPACT":
      return compact;
    case "ZRX":
      return zrx;
    case "ALTIJET":
      return altijet;
  }
}
